
import { LoggerConfiguration, ConsoleSink, ColoredConsoleSink, SeqSink } from 'serilogger';
import { setJSExceptionHandler, getJSExceptionHandler } from 'react-native-exception-handler';

const log = new LoggerConfiguration()
    .enrich({
        'Application': 'DocOK',
        'Source': 'APP',
        'Device': 'test',   // ! TODO add
        'AppVersion': 'test',
    })
    .writeTo(new ColoredConsoleSink())
    .writeTo(new SeqSink({
        url: "https://logging.infominds.eu",
        apiKey: "mkDwV6e2kAWFvgI3XZsD",

    }))
    .create();

export default log;

export const setupExceptionHandler = () => {
    setJSExceptionHandler((error, isFatal) => {
        log.error('Unhandled Exception: {ExceptionName} message: {Message} stacktrace: {Stacktrace}', error.name, error.message, error.stack);
    });
}


// log.info('Hello {Name}!', 'Greg');