import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Text, View, TouchableOpacity, useThemeColor } from './Themed';
import Colors from '../constants/Colors';
import ListItem from './ListItem';
import Moment from 'moment'
import { Platform, RefreshControl, StyleSheet } from 'react-native';
import { SwipeListView } from "react-native-swipe-list-view";
import Icon from '../components/Icons/Icons'
import useColorScheme from "../hooks/useColorScheme";
import { useNavigation } from "@react-navigation/native";
import api from "../apis/Api";
import Spinner from "react-native-modal-spinner";
import LanguageContext from "../contexts/LanguageContext";
import Layout from "../constants/Layout";

function BillsList(props: { isOthers: any, HasRightSwipe: boolean, isArchive: true, data: any, searchText: string, groupBy: any, refresh: any, onSelectedBill: any, refreshing: boolean }) {
    const navigation = useNavigation();
    const colorScheme = useColorScheme();
    const lang = React.useContext(LanguageContext);

    // for performance reasons create const
    const isOthers = props.isOthers;
    const hasRightSwipe = props.HasRightSwipe;
    const isArchive = props.isArchive;
    let bills = props.data;

    // let [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);
    let [selectedItem, setSelectedItem] = useState(0);

    let groupedBills = useMemo((): any[] => {
        console.debug('start filtering');
        // filer bills by search term
        if (props.searchText != "")
            bills = bills.filter((item: any) => (item.supplierName.toLowerCase().includes(props.searchText.toLowerCase()) || item.billID.toLowerCase().includes(props.searchText.toLowerCase())));
        // console.debug('end filtering');

        // group bills by property
        const groupByKey = (list: any[], key: string) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
        let data = [];
        if (bills) {
            let tmp = groupByKey(bills, props.groupBy);

            console.log(props.groupBy);

            for (let [key, value] of Object.entries(tmp)) {
                if (props.groupBy === 'billDate')    //['billDate', 'supplierName', 'firstDueDate'];
                    key = lang.ID_BILLDATE + ': ' + Moment(key).format('DD/MM/yyyy')
                else if (props.groupBy === 'firstDueDate')
                    key = lang.ID_DUEDATE + ': ' + Moment(key).format('DD/MM/yyyy')

                data.push({ title: key, data: value })
            }
            // console.debug('end grouping');

        }
        return data;
    }, [props.searchText, props.groupBy, bills]);

    let approve = (item: any, accept: boolean) => {
        setLoading(true);

        new Promise((resolve, reject) => {
            console.debug('accept: ' + item.msgId + ' ' + accept + ' ');
            api.approve(item.msgId, item.billID, item.supplierID, item.billDate, accept, '')
                .then((result: any) => {
                    props.refresh();
                    resolve(result);
                })
                .catch((error: any) => {
                    console.error(error)
                    reject(error);
                })
        }).finally(() => setLoading(false))
    }

    const renderItem = ({ item, index }: any) => {

        return (
            <ListItem
                item={item}
                isSelected={!Layout.isSmallDevice && item.billID == selectedItem}
                isOthers={isOthers}
                isArchive={isArchive}
                onPress={() => {
                    props.onSelectedBill(item);
                    if (!Layout.isSmallDevice)
                        setSelectedItem(item.billID)
                }}
                onStatusPress={() => {
                    // if (!isArchive)
                    navigation.navigate('HistoryScreen', { bill: item })
                }} />
        );
    };

    const renderSectionHeader = ({ section: { title } }: any) => {
        return (
            <View>
                <Text style={styles.listHeader}>{title}</Text>
            </View>
        )
    };

    const renderHiddenItem = ({ item }: any) => (
        <View style={styles.rowBack}>
            <TouchableOpacity
                style={styles.backLefttBtn}
                onPress={() => {

                    navigation.navigate('PreviewScreen', { bill: item });
                }}>
                <Icon size={28} style={styles.backTextWhite} name="file" />
            </TouchableOpacity>
            {hasRightSwipe &&
                <React.Fragment>
                    <TouchableOpacity
                        style={[styles.backRightBtn, styles.backRightBtnLeft]}
                        onPress={() => approve(item, true)}>
                        <Icon size={28} style={styles.backTextWhite} name="thumbs-up" />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.backRightBtn, styles.backRightBtnRight]}
                        onPress={() => approve(item, false)}>
                        <Icon size={28} style={styles.backTextWhite} name="thumbs-down" />
                    </TouchableOpacity>
                </React.Fragment>
            }
        </View>
    );

    const backgroundColor = useThemeColor({ light: Colors.light.background, dark: Colors.dark.background }, 'background');
    const rightOffset = hasRightSwipe ? -150 : 0;

    return (
        <View style={styles.container}
            lightColor={Colors.light.background}
            darkColor={Colors.dark.background}>
            {Platform.OS !== 'web' ?
                <Spinner
                    color={Colors.tint}
                    size="large"
                    backgroundColor="transparent"
                    visible={loading}
                /> : <></>}

            <SwipeListView
                sections={groupedBills}
                useSectionList={true}
                renderItem={renderItem}
                keyExtractor={(item: any, index: number) => item.billID + index}
                refreshing={props.refreshing}
                refreshControl={
                    <RefreshControl
                        refreshing={props.refreshing}
                        onRefresh={() => props.refresh()} colors={[Colors.tint]} tintColor={Colors.tint} />
                }
                renderSectionHeader={renderSectionHeader}
                renderHiddenItem={renderHiddenItem}
                leftOpenValue={75}
                rightOpenValue={rightOffset}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 2,
        justifyContent: 'space-between'
    },
    rowBack: {
        margin: 2,
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    swipeRight: {
        backgroundColor: Colors.tint,
        paddingRight: 15,
        paddingLeft: 15,
        height: '100%',
    },
    backTextWhite: {
        color: '#FFF'
    },
    detailPage: {
        margin: 10
    },
    backLefttBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: Colors.tint,
        top: 0,
        width: 75,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: Colors.tint,
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: Colors.tintOpposite,
        right: 0,
    },
    listHeader: {
        margin: 10
    },
    searchbar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    itemLeft: {
        width: '90%',
        height: '100%'
    },
    itemRight: {
        padding: 5,
        height: '100%',
        marginTop: 10
    },
    input: {
        height: 40,
        minHeight: 40,
        margin: 10,
        padding: 10,
        borderWidth: 0,
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: "space-between"

    },
});

export default BillsList;