import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import NotFoundScreen from '../screens/NotFoundScreen';
import { LoginScreen } from '@infominds/react-native-license';
import PreviewScreen from '../screens/PreviewScreen';
import HistoryScreen from '../screens/HistoryScreen';
import DetailScreen from '../screens/DetailScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import DelegateScreen from '../screens/DelegateScreen';
import Colors from '../constants/Colors';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {

  return (
    <NavigationContainer
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme} >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }} >
      <Stack.Screen name="Login">
        {props =>
          <LoginScreen {...props}
            isOffline={false}
            timeoutInMs={10000}
            projectCode={'APP-DOCOK'}
            modulCode={'APP'}
            demoData={{ lic: 'DEMOLIC-DOCOK', username: 'AM', password: '' }}
            iconSource={require('../assets/images/app_logo.png')}
          />
        }
      </Stack.Screen>

      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerLeft: undefined }} />
      <Stack.Screen name="Preview" component={PreviewScreen} />
      <Stack.Screen name="Detail" component={DetailScreen} />
      <Stack.Screen name="Delegate" component={DelegateScreen} options={{ headerShown: true, headerTintColor: Colors.tint, headerBackTitle: '' }} />
      <Stack.Screen name="History" component={HistoryScreen} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}
