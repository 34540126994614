import * as React from 'react';
import { PdfViewer } from '../components/PdfViewer/PdfViewer';
import api from '../apis/Api';
import useNoBottomNavigation from '../hooks/useNoBottomNavigation';

export default function PreviewScreen({ route, navigation }: any) {
    useNoBottomNavigation(navigation);

    const { bill } = route.params;

    return (
        <PdfViewer navigation={navigation} load={() => api.getFile(bill.billID, bill.supplierID, bill.billDate)} />
    );
}
