import * as React from 'react';
import { Alert, Platform, StyleSheet } from 'react-native';

import { Text, View } from '../components/Themed';
import BillsList from '../components/BillsList';
import SearchBar from '../components/SearchBar';
import Layout from '../constants/Layout';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import DetailView from './DetailView';
import MasterDetail from './MasterDetail';
import CommentView from './CommentView';
import createPersistedState from 'use-persisted-state';
import global from '../global';
import AsyncStorage from '@react-native-community/async-storage';
import { useState } from 'react';
import Spinner from "react-native-modal-spinner";
import Colors from '../constants/Colors';

const useSelectedGroupByState = createPersistedState('selectedGroupBy');
const useGroupByState = createPersistedState('groupBy');

export default function BillListView(props: any) {
    const navigation = useNavigation();

    let [data, setData] = useState([]);
    let [selectedBill, setSelectedBill] = useState<any>();

    let [search, updateSearch] = useState("");

    const [loading, setLading] = useState<boolean>(false)

    const groupByOptions = ['billDate', 'supplierName', 'firstDueDate'];
    let [selectedGroupBy, setSelectedGroupBy] = useSelectedGroupByState(0);
    let [groupBy, setGroupBy] = useGroupByState('billDate');

    let nexGroupBy = () => {
        setSelectedGroupBy(++selectedGroupBy % groupByOptions.length);
        let index = selectedGroupBy - 1;
        console.log(index)
        if (index < 0)
            index = 0;
        setGroupBy(groupByOptions[index]);
    };

    useFocusEffect(
        React.useCallback(() => {
            reload();
        }, [])
    );

    const reload = () => {
        setLading(true);
        props.load().then(async (result: any) => {

            let dowait = await AsyncStorage.getItem('wait');

            if (!props.isArchive && !JSON.parse(String(dowait))) {
                // filter blacklisted bills out
                console.log('Blacklist', global.billBlacklist);
                global.billBlacklist.forEach((blackbill: any) => {
                    let idx = result.data.findIndex(
                        (databill: any) => {
                            // { billID, supplierID, billDate }
                            return (blackbill.billID == databill.billID &&
                                blackbill.supplierID == databill.supplierID &&
                                blackbill.billDate == databill.billDate)
                        }
                    );

                    if (idx >= 0) {
                        result.data.splice(idx, 1);
                    }
                })
            }

            // if selected bill is not in the new data, reset the state
            AsyncStorage.getItem('changedMandant').then(cm => {
                const changed = cm ? Boolean(cm) : false;
                console.log(cm);
                if (changed) {
                    setSelectedBill(undefined);
                    AsyncStorage.removeItem('changedMandant');
                }
            })

            return setData(result.data);
        }).finally(() => { setLading(false) });
    }

    const RenderDetail = () => {
        if (selectedBill) {
            if (!props.isArchive)
                return (<DetailView bill={selectedBill}></DetailView >)
            else
                return (<CommentView bill={selectedBill}></CommentView>)
        } else {
            return (<View style={styles.detailPage}></View>)
        }
    }

    return (
        <View style={styles.container}>
            {(Platform.OS !== 'web' && !Layout.isSmallDevice) ?
                <Spinner
                    color={Colors.tint}
                    size="large"
                    backgroundColor="transparent"
                    visible={loading}
                /> : <></>}
            <MasterDetail
                master={
                    <View style={{ height: '100%' }}>
                        <SearchBar
                            text={search}
                            onChangeText={updateSearch}
                            onGroupPress={() => nexGroupBy()}>
                        </SearchBar>
                        <BillsList
                            data={data}
                            refreshing={loading && Layout.isSmallDevice}
                            refresh={reload}
                            onSelectedBill={(item: any) => {
                                if (!Layout.isSmallDevice) {
                                    console.log('Setting', item);
                                    setSelectedBill(item);
                                } else {
                                    if (!props.isArchive)
                                        navigation.navigate('DetailScreen', { bill: item })
                                    else
                                        navigation.navigate('HistoryScreen', { bill: item })
                                    // else
                                    //     navigation.navigate('PreviewScreen', { bill: item });
                                }
                            }}
                            searchText={search}
                            groupBy={groupBy}
                            isOthers={true}
                            isArchive={props.isArchive}
                            HasRightSwipe={!props.isArchive} />
                    </View>
                }
                detail={
                    <RenderDetail></RenderDetail >
                }>


            </MasterDetail>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 2,
        flexDirection: 'column'
    },
    root: { flex: 1, flexDirection: 'row' },
    masterView: { flex: 1, maxWidth: 400, borderWidth: 0 },
    detailView: {
        flex: 1,
        overflow: 'hidden',
        borderLeftWidth: 1
    },
    detailPage: {
        margin: 10
    },
});
