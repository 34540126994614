import React from "react";
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import LanguageContext from "../contexts/LanguageContext";
import { View } from "./Themed";
import Colors from '../constants/Colors';

function StateBadge({ state, onPress, isOthers, hasComment }: any) {
    let lang = React.useContext(LanguageContext);

    let StateText = () => {
        switch (state) {
            case 'OK':
                return lang.ID_OK_1;
            case 'NOTOK':
                return lang.ID_KO;
            case 'RETURNED':
                return lang.ID_RETURN;
            case 'FORWARDED':
                return lang.ID_FORWARDED;
            case 'UNREAD':
                return '';   //TODO
            case 'READED':
                return '';   //TODO
            case 'DELETED':
                return state;   //TODO
            case 'TASKDONE':
                return lang.ID_TASKDONE;
            case 'PARTIALOK':
                return lang.ID_PARTIALOK;
            case 'OPEN':
                return lang.ID_OPEN;
            default:
                return '';
        }
    }

    let backgroundLightColor, backgroundDarkColor;
    if ((StateText() !== ''))
        return (
            <View style={{ backgroundColor: 'transparent' }}>
                <TouchableOpacity
                    onPress={onPress}
                    style={[styles.badge, [(state === "NOTOK") ? styles.notOk : styles.ok]]}>
                    <Text style={styles.text}>{isOthers && lang.ID_OTHERS + ': '}{StateText()}</Text>
                </TouchableOpacity>
                { hasComment ?
                    <View
                        style={{
                            backgroundColor: Colors.tintOpposite,
                            position: 'absolute',
                            top: -1,
                            right: -1,
                            height: 12,
                            width: 12,
                            borderRadius: 8
                        }}
                    />
                    : <View></View>
                }
            </View>
        );
    else
        return (<View />);
}

const styles = StyleSheet.create({
    badge: {
        padding: 10,
        borderRadius: 20,
        opacity: 1
    },
    ok: {
        backgroundColor: Colors.tint
    },
    notOk: {
        backgroundColor: Colors.tintOpposite
    },
    text: {
        color: '#fff'
    }
});

export default StateBadge;
