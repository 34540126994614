import React, { useContext, useEffect, useState } from 'react';
import Moment from 'moment'
import { useNavigation } from "@react-navigation/native";
import useNoBottomNavigation from '../hooks/useNoBottomNavigation';
import LanguageContext from '../contexts/LanguageContext';
import api from '../apis/Api';
import Colors from '../constants/Colors';
import { Text, TouchableOpacity, View } from './Themed';
import { Col, Grid, Row } from 'react-native-easy-grid';
import StateBadge from './StateBadge';
import { Comment } from './Comment';
import Icon from './Icons/Icons';
import useColorScheme from '../hooks/useColorScheme';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from 'react-native';
// @ts-ignore
import Spinner from "react-native-modal-spinner";
import Layout from '../constants/Layout';
import { TextInput } from 'react-native-gesture-handler';
import LicenseGlobals from '@infominds/react-native-license';

function DetailView(props: any) {
    const navigation = useNavigation();
    useNoBottomNavigation(navigation);
    const colorScheme = useColorScheme();

    const lang = useContext(LanguageContext);
    const bill = props.bill;

    const [comment, onChangeText] = useState('');
    const [loading, setLoading] = useState(false);

    const [detail, setDetail] = useState<any>();

    useEffect(() => {
        api.getBillDetail(bill.billID, bill.supplierID, bill.billDate).then((res: any) => {
            setDetail(res);
            console.log(res)
            console.log(bill)
        }).catch(error => console.log(error));
    }, []);

    function roundToTwo(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    function formatCurrenc(num: number, currency: string) {
        if (num > 0)
            return roundToTwo(num).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + currency;
        return '';
    }

    function format(amount: number, currency: string, id: number, description: string): string {
        let result = formatCurrenc(amount, currency);

        if (id > 0) {
            if (result !== '')
                result += ' - ' + id;
            else
                result += id;
        }
        if (description !== '')
            if (id > 0)
                result += ' - ' + description;
            else
                result += description;

        return result;
    }

    let approve = (accept: boolean) => {
        setLoading(true);

        new Promise((resolve, reject) => {
            api.approve(bill.msgId, bill.billID, bill.supplierID, bill.billDate, accept, comment)
                .then((result: any) => {
                    //console.debug(result);
                    resolve(result);
                    // @ts-ignore
                    navigation.popToTop();
                })
                .catch((error: any) => {
                    console.error(error)
                    reject(error);
                })
        }).finally(() => setLoading(false))
    }

    let returnBill = () => {
        setLoading(true);

        new Promise((resolve, reject) => {

            api.billReturn(bill.msgId, bill.billID, bill.supplierID, bill.billDate, comment, lang.NORETURN)
                .then((result) => {
                    //console.debug(result);
                    resolve(result);
                    // @ts-ignore
                    navigation.popToTop();

                })
                .catch((error: any) => {
                    console.error(error)
                    reject(error);
                })
        }).finally(() => setLoading(false))
    }

    return (
        // <KeyboardAvoidingView
        //     behavior={Platform.OS === "ios" ? "padding" : "height"}
        //     style={styles.containerScroll}
        // >
        <ScrollView style={{ backgroundColor: Colors[colorScheme].background }}>
            <View style={styles.container}
                lightColor={Colors.light.background}
                darkColor={Colors.dark.background}>

                {Platform.OS !== 'web' ?
                    <Spinner
                        color={Colors.tint}
                        size="large"
                        backgroundColor="transparent"
                        visible={loading}
                    /> : <></>}

                <View>
                    <Text style={styles.header}>{bill.supplierName}</Text>
                    <View style={styles.detail}>
                        <Text>{lang.ID_BILLNR} {bill.billID} {lang.ID_FROM} {Moment(bill.billDate).format('DD/MM/yyyy')}</Text>
                        <Text>{lang.BASEPRICE}: {formatCurrenc(bill.vatBasisAmount, bill.currency)}</Text>
                        <Text>{lang.VAT}: {formatCurrenc(bill.vatAmount, bill.currency)}</Text>
                        <Text>{lang.TOTAL}: {formatCurrenc(bill.billAmount, bill.currency)}</Text>

                        <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.DEADLINE}:</Text>
                        {bill.dueDate.map((dd: any, idx: number) => {
                            return <Text key={idx}>{formatCurrenc(dd.amount, bill.currency)} - {Moment(dd.date).format('DD/MM/yyyy')}</Text>
                        })}

                        {detail ?
                            <View>
                                {detail.accounting.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.ACCOUNT}:</Text>}
                                {detail.accounting.map((a: any, idx: number) =>
                                    <Text key={idx}>
                                        {format(a.amount, bill.currency, a.id, a.description)}
                                    </Text>
                                )}

                                {detail.cdC.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.CDC}:</Text>}
                                {detail.cdC.map((c: any, idx: number) =>
                                    <Text key={idx}>
                                        {format(c.amount, bill.currency, c.id, c.description)}
                                    </Text>
                                )}

                                {detail?.objects?.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.OBJECT}:</Text>}
                                {detail?.objects?.map((o: any, idx: number) =>
                                    <Text key={idx}>
                                        {format(o.amount, bill.currency, o.id, o.description)}
                                    </Text>
                                )}

                                {detail?.projects?.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.PROJECTS}:</Text>}
                                {detail?.projects?.map((o: any, idx: number) =>
                                    <Text key={idx}>
                                        {format(o.amount, bill.currency, o.id, o.description)}
                                    </Text>
                                )}
                            </View>
                            :
                            <></>
                        }
                    </View>
                </View>
                <View style={styles.previewAndStateRow}>
                    <Grid >
                        <Col size={50}>
                            <TouchableOpacity
                                lightColor={Colors.light.listItemBackground}
                                darkColor={Colors.dark.listItemBackground}
                                style={[styles.button, styles.textButton]}
                                onPress={() => navigation.navigate('PreviewScreen', { bill: bill })}>
                                <Text>{lang.ID_VORSCHAU}</Text>
                            </TouchableOpacity>
                        </Col>
                        <Col size={50} style={{ alignItems: 'flex-end' }}>
                            <StateBadge
                                state={bill.billState}
                                onPress={() => navigation.navigate('HistoryScreen', { bill: bill })}
                                isOthers={true}
                                hasComment={bill.msgCount > 0} />
                        </Col>
                    </Grid>
                </View>
                <View style={styles.comment}>
                    <Comment
                        onChangeText={(text: string) => onChangeText(text)}
                        value={comment}
                        multiline={true}
                        scrollEnabled={false}   // workaround to get focus on click
                        style={{ color: Colors[colorScheme].text, borderColor: Colors[colorScheme].listItemDetail, textAlignVertical: 'top' }}
                        lightColor={Colors.light.listItemBackground}
                        darkColor={Colors.dark.listItemBackground}
                    />
                </View>
                <View style={styles.grid}>
                    <Grid>
                        <Row>
                            <Col style={{ marginRight: 10 }}>
                                <TouchableOpacity
                                    style={[styles.button, styles.primaryButton]}
                                    onPress={() => approve(true)}>
                                    <Icon size={28} name="thumbs-up" color='#fff' />
                                </TouchableOpacity>
                            </Col>
                            <Col>
                                <TouchableOpacity
                                    style={[styles.button, styles.secondaryButton]}
                                    onPress={() => approve(false)}>
                                    <Icon size={28} name="thumbs-down" color='#fff' />
                                </TouchableOpacity>
                            </Col>
                        </Row>
                        <Row>
                            {LicenseGlobals.erp !== 'radix' ?
                                <Col style={{ marginRight: 10 }}>
                                    <TouchableOpacity
                                        style={[styles.button, styles.textButton]}
                                        lightColor={Colors.light.listItemBackground}
                                        darkColor={Colors.dark.listItemBackground}
                                        onPress={() => returnBill()}>
                                        <Text>{lang.ID_RETURN}</Text>
                                    </TouchableOpacity>
                                </Col>
                                : <></>}
                            <Col>
                                <TouchableOpacity
                                    style={[styles.button, styles.textButton]}
                                    lightColor={Colors.light.listItemBackground}
                                    darkColor={Colors.dark.listItemBackground}
                                    onPress={() => {
                                        bill.comment = comment;
                                        navigation.navigate('Delegate', { bill: bill })
                                    }
                                    } >
                                    <Text>{lang.ID_DELEGATE}</Text>
                                </TouchableOpacity>
                            </Col>
                        </Row>
                    </Grid >
                </View >

            </View >
        </ScrollView>
        // </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    containerScroll: {
        flex: 1
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10
    },
    detail: {
        marginBottom: 20
    },
    previewAndStateRow: {
        width: '100%',
        height: 50,
    },
    grid: {
        width: '100%',
        height: 100,
    },
    comment: {
        width: '100%',
        height: 100,
        marginTop: 10,
        marginBottom: 10
    },
    button: {
        marginBottom: 10,
        alignItems: "center",
        borderRadius: 5,
        padding: 5
    },
    textButton: {
        padding: 10
    },
    primaryButton: {
        backgroundColor: Colors.tint
    },
    secondaryButton: {
        backgroundColor: Colors.tintOpposite
    }
});

export default DetailView;