import React, { useContext } from "react"
import { Text, View, TouchableHighlight, TouchableOpacity } from '../components/Themed';
import Colors from '../constants/Colors';
import Moment from 'moment'
import { StyleSheet } from 'react-native';
import LanguageContext from "../contexts/LanguageContext";
import StateBadge from "./StateBadge";

function ListItem({ item, onPress, onStatusPress, style, isOthers, isArchive, isSelected }: any) {
    let lang = React.useContext(LanguageContext);

    let state = item.billState;

    isOthers = false;
    if (item.otherEmployeesState != 'UNDEF') {
        state = item.otherEmployeesState;
        isOthers = true;
    } else {
        if (!isArchive)
            state = ''
    }


    return (
        <TouchableHighlight
            onPress={onPress}
            style={[styles.item, style, isSelected && { borderColor: Colors.tint, borderLeftWidth: 5 }]}
            lightColor={Colors.light.listItemBackground}
            darkColor={Colors.dark.listItemBackground} >
            <View style={[styles.itemcontainer, style]}
                lightColor={Colors.light.listItemBackground}
                darkColor={Colors.dark.listItemBackground}>
                <View style={styles.itemLeft}
                    lightColor={Colors.light.listItemBackground}
                    darkColor={Colors.dark.listItemBackground}>
                    <Text style={styles.title}>
                        {item.billID} {lang.ID_FROM} {Moment(item.billDate).format('DD/MM/yyyy')}</Text>
                    <Text
                        lightColor={Colors.light.listItemDetail}
                        darkColor={Colors.dark.listItemDetail}>
                        {lang.ID_TOTAL_SHORT}: {item.billAmount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        {' ' + item.currency} -
                        {' ' + lang.ID_DUEDATE_SHORT}: {Moment(item.firstDueDate).format('DD/MM/yyyy')}
                    </Text>
                    <Text
                        lightColor={Colors.light.listItemDetail}
                        darkColor={Colors.dark.listItemDetail}>{item.supplierName}</Text>
                </View>
                <View style={styles.itemRight}
                    lightColor={Colors.light.listItemBackground}
                    darkColor={Colors.dark.listItemBackground} >
                    {(state == '' && !isArchive) ? <View></View> :
                        <StateBadge
                            state={state}
                            onPress={onStatusPress}
                            isOthers={isOthers}
                            hasComment={item.msgCount > 0} />
                    }
                </View>
            </View>
        </TouchableHighlight>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    item: {
        margin: 1,
        fontSize: 18,
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    itemcontainer: {
        margin: 1,
        padding: 10,
        fontSize: 18,
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"

    },
    itemLeft: {
        width: '70%'
    },
    itemRight: {
        margin: 5,
        alignSelf: 'flex-end',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default ListItem;