import { NativeModules, Platform } from 'react-native'
import global from '../global'

const de = require('../assets/languages/de.json');
const en = require('../assets/languages/en.json');
const it = require('../assets/languages/it.json');

export default function useLanguage(newLanguage = '') {

    let locale;
    if (newLanguage !== '') {
        locale = newLanguage;
    } else {
        if (Platform.OS === 'ios')
            locale = NativeModules.SettingsManager.settings.AppleLocale
        else if (Platform.OS === 'android')
            locale = NativeModules.I18nManager.localeIdentifier;
        else
            locale = 'de';

        if (!locale)
            locale = 'de';

        locale = locale.substring(0, 2);
    }

    global.language = locale;

    switch (locale) {
        case 'en':
            return en;
        case 'de':
            return de;
        case 'it':
            return it;
        default:
            return en;
    }
}