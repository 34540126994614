import React from 'react';
import CommentView from '../components/CommentView';
import { View } from '../components/Themed';

export default function HistoryScreen({ route, navigation }: any) {

    return (
        <CommentView bill={route.params.bill}></CommentView>
    );
}

