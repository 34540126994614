import * as React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import useColorScheme from '../hooks/useColorScheme';


const MasterDetail = ({ master, detail }: any) => {
    if (Layout.isSmallDevice) {
        return <View>{master}</View>;
    } else {
        return (
            <View style={styles.root}>
                <View style={styles.masterView}>{master}</View>
                <View style={[styles.detailView, { borderLeftColor: useColorScheme() === 'light' ? Colors.light.listItemBackground : '#424242' }]}>{detail}</View>
            </View>
        )
    }
};

const styles = StyleSheet.create({
    root: { flex: 1, flexDirection: 'row' },
    masterView: { flex: 1, maxWidth: 400, borderWidth: 0 },
    detailView: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        borderLeftWidth: 1
    },
});
export default MasterDetail;