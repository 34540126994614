import { useEffect } from 'react';
import Layout from '../constants/Layout';

export default function useNoBottomNavigation(navigation: any) {
  if (Layout.isSmallDevice) {
    return useEffect(() => {
      const parent = navigation.dangerouslyGetParent();
      parent.setOptions({
        tabBarVisible: false
      });
      return () =>
        parent.setOptions({
          tabBarVisible: true
        });
    }, []);
  } else {
    return undefined;
  }
}
