import AsyncStorage from "@react-native-community/async-storage"
import global from '../global';
import Analytics from 'appcenter-analytics';

import { LicenseGlobals } from '@infominds/react-native-license'
import { Alert } from "react-native";


function createHeader() {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", LicenseGlobals.token);

        if (LicenseGlobals.mandantId != -1 && LicenseGlobals.mandantId != null)
            myHeaders.append("mandantID", LicenseGlobals.mandantId);

        console.log(myHeaders);

        resolve(myHeaders);
    });
};

// if the user doesnt want to wait (default) method will return after a 
function dontWaitResolve(billID: string, supplierID: number, billDate: string, resolve: any) {
    AsyncStorage.getItem('wait').then(wait => {
        if (Boolean(wait)) {
            global.billBlacklist.push({ billID, supplierID, billDate });

            resolve('timeout');
        }
    })
}


const api = {
    getUserInfo() {
        return new Promise((resolve, reject) => {
            AsyncStorage.getItem('username').then((username) => {
                if (username != null) {
                    AsyncStorage.getItem('password').then((password) => {
                        if (password != null) {

                            var myHeaders = new Headers();
                            myHeaders.append("Authorization", LicenseGlobals.token);
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("grant_type", "password");
                            urlencoded.append("client_id", "IM.Api");
                            urlencoded.append("client_secret", "21B5F798-BE55-42BC-8AA8-0025B903DC3B");
                            urlencoded.append("scope", "api1 openid IdentityServerApi");
                            urlencoded.append("username", username);
                            urlencoded.append("password", password);

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded
                            };

                            fetch(LicenseGlobals.baseUrl + "/connect/userinfo", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log('hallo')
                                    console.log(result);
                                    resolve(result);

                                })
                                .catch(error => {
                                    console.error('error', error);
                                    reject(error);
                                });

                        }
                    }).catch((error) => console.error(error));;
                }
            }).catch((error) => console.error(error));
        })
    },

    getBills() {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders
                    };


                    fetch(LicenseGlobals.baseUrl + "/Bills", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            // console.log(result);

                            resolve(result)
                        })
                        .catch(error => {
                            console.error('error', error);
                            reject(error);
                        });

                })
                .catch((error => console.error(error)));
        })

    },

    getBillDetail(billId: string, supplierID: number, billDate: any) {
        return new Promise((resolve, reject) => {
            createHeader().then((myHeaders: Headers | any) => {
                myHeaders.append("Content-Type", "application/json");

                console.log({
                    'BillID': billId,
                    'SupplierID': supplierID,
                    'BillDate': billDate
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                        'BillID': billId,
                        'SupplierID': supplierID,
                        'BillDate': billDate
                    })
                };

                fetch(LicenseGlobals.baseUrl + '/Bills/detail', requestOptions)
                    .then(response => {
                        if (response.status === 200)
                            return response.json();
                        throw new Error(response.status.toString())
                    })
                    .then(result => { resolve(result.data); })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });

        })
    },

    getArchive(historyDays = -1) {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {
                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders
                    };

                    let url = LicenseGlobals.baseUrl + "/Bills/archive";
                    if (historyDays != -1)
                        url = url + "/" + historyDays;

                    console.log(url)

                    fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(result => resolve(result))
                        .catch(error => {
                            console.error('error', error);
                            reject(error);
                        });
                })
                .catch((error => console.error(error)));
        })
    },

    getEmployees() {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders
                    };

                    fetch(LicenseGlobals.baseUrl + "/Employees", requestOptions)
                        .then(response => response.json())
                        .then(result => resolve(result.data))
                        .catch(error => {
                            console.error('error', error);
                            reject(error);
                        });
                })
                .catch((error => console.error(error)));
        })
    },

    getMandants() {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {
                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders
                    };

                    fetch(LicenseGlobals.baseUrl + "/mandants", requestOptions)
                        .then(response => response.json())
                        .then(result => resolve(result.data))
                        .catch(error => {
                            console.error('error', error);
                            reject(error);
                        });
                })
                .catch((error => console.error(error)));
        })
    },

    getHistory(billId: string, supplierID: Int16Array, billDate: any) {
        return new Promise((resolve, reject) => {

            createHeader()
                .then((myHeaders: Headers | any) => {
                    myHeaders.append("Content-Type", "application/json");

                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: JSON.stringify({
                            'BillID': billId,
                            'SupplierID': supplierID,
                            'BillDate': billDate
                        })
                    };

                    fetch(LicenseGlobals.baseUrl + "/Bills/history", requestOptions)
                        .then(response => { return response.json(); })
                        .then(result => { resolve(result.data); })
                        .catch(error => {
                            console.error('error', error);
                            reject(error);
                        });
                });

        })
    },

    getFile(billId: string, supplierID: number, billDate: any) {
        return new Promise((resolve, reject) => {
            createHeader().then((myHeaders: Headers | any) => {
                myHeaders.append("Content-Type", "application/json");

                console.log({
                    'BillID': billId,
                    'SupplierID': supplierID,
                    'BillDate': billDate
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                        'BillID': billId,
                        'SupplierID': supplierID,
                        'BillDate': billDate
                    })
                };

                fetch(LicenseGlobals.baseUrl + '/Bills/file', requestOptions)
                    .then(response => { return response.json(); })
                    .then(result => { resolve(result.data); })
                    .catch(error => {
                        console.error('error', error);
                        reject(error);
                    });
            });

        })
    },

    billReturn(msgID: number, billID: string, supplierID: number, billDate: string, comment: string, errormsg: string) {
        return new Promise((resolve, reject) => {
            createHeader().then((myHeaders: Headers | any) => {

                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({ "msgID": msgID, "billID": billID, "comment": comment });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw
                };

                dontWaitResolve(billID, supplierID, billDate, resolve);

                fetch(LicenseGlobals.baseUrl + "/Bills/return", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log('Return result: ', result);
                        if (result.status == 'fail') {
                            Alert.alert(errormsg);

                            let idx = global.billBlacklist.findIndex(
                                (databill: any) => {
                                    // { billID, supplierID, billDate }
                                    return (billID == databill.billID &&
                                        supplierID == databill.supplierID &&
                                        billDate == databill.billDate)
                                }
                            );

                            if (idx >= 0) {
                                global.billBlacklist.splice(idx, 1);
                            }
                        }

                        Analytics.trackEvent("Bill event", { operation: "return" });
                        resolve(result.data);
                    }).catch(error => {
                        console.error('error', error);
                        reject(error)
                    });
            })
                .catch((error => console.error(error)));
        })
    },

    approve(msgID: number, billID: string, supplierID: number, billDate: string, approved: boolean, comment: string) {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {

                    myHeaders.append("Content-Type", "application/json");

                    let raw;
                    if (comment !== '')
                        raw = JSON.stringify({ "msgID": msgID, "approved": approved ? 1 : 0, "comment": comment, "billId": billID });
                    else
                        raw = JSON.stringify({ "msgID": msgID, "approved": approved ? 1 : 0, "billId": billID });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw
                    };

                    console.log(requestOptions);

                    dontWaitResolve(billID, supplierID, billDate, resolve);

                    fetch(LicenseGlobals.baseUrl + "/Bills/approve", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.debug('Response: ', result);
                            Analytics.trackEvent("Bill event", { operation: "approve" });
                            resolve(result);
                        })
                        .catch(error => {
                            console.error('error', error);
                            reject(error)
                        });
                })
                .catch((error => console.error(error)));
        })
    },

    delegate(msgID: number, billID: string, supplierID: number, billDate: string, employeeId: Number, comment: string) {
        return new Promise((resolve, reject) => {
            createHeader()
                .then((myHeaders: Headers | any) => {

                    myHeaders.append("Content-Type", "application/json");

                    var raw = ''
                    if (comment !== '')
                        raw = JSON.stringify({ "msgID": msgID, "billID": billID, "comment": comment, "employeeId": employeeId });
                    else
                        raw = JSON.stringify({ "msgID": msgID, "billID": billID, "employeeId": employeeId });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw
                    };

                    dontWaitResolve(billID, supplierID, billDate, resolve);

                    fetch(LicenseGlobals.baseUrl + "/Bills/delegate", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            Analytics.trackEvent("Bill event", { operation: "delegate" });
                            resolve(result.data);
                        })
                        .catch(error => {
                            console.error('error', error);
                            reject(error)
                        });

                })
                .catch((error => console.error(error)));
        })
    },

}

export default api;