import React, { useState } from "react"
import { View, TouchableOpacity, TextInput } from './Themed';
import Colors from '../constants/Colors';
import { StyleSheet } from 'react-native';
import Icon from '../components/Icons/Icons'
import useColorScheme from "../hooks/useColorScheme";


function SearchBar(props: any) {
    const colorScheme = useColorScheme();

    return (
        <View style={{ height: 55 }}>
            <View style={styles.searchbar}>
                <View style={styles.itemLeft}>
                    <TextInput
                        lightColor={Colors.light.listItemBackground}
                        darkColor={Colors.dark.listItemBackground}
                        placeholderTextColor={Colors[colorScheme].text}
                        placeholder="Search..."
                        style={[styles.input, { color: Colors[colorScheme].text }]}
                        onChangeText={(text) => {
                            props.onChangeText(text);
                        }}
                        value={props.text} ></TextInput>
                </View>
                <View style={styles.itemRight}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TouchableOpacity
                            style={{ marginRight: 10 }}
                            onPress={props.onGroupPress}>
                            <Icon size={28} name="list" color={Colors[colorScheme].text} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </View>
    );


}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 2,
        justifyContent: 'space-between'
    },
    rowBack: {
        margin: 2,
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    swipeRight: {
        backgroundColor: Colors.tint,
        paddingRight: 15,
        paddingLeft: 15,
        height: '100%',
    },
    backTextWhite: {
        color: '#FFF'
    },
    detailPage: {
        margin: 10
    },
    backLefttBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: Colors.tint,
        top: 0,
        width: 75,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: Colors.tint,
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: Colors.tintOpposite,
        right: 0,
    },
    listHeader: {
        margin: 10
    },
    searchbar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    itemLeft: {
        flex: 1
    },
    itemRight: {
        padding: 5,
        height: '100%',
        marginTop: 10
    },
    input: {
        height: 40,
        minHeight: 40,
        margin: 10,
        padding: 10,
        borderWidth: 0,
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: "space-between"

    },
});

export default SearchBar;