import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FlatList, Platform, StyleSheet } from 'react-native';
import { View, Text, TouchableOpacity, TextInput, TouchableHighlight } from '../components/Themed';
import LanguageContext from '../contexts/LanguageContext';
import Icon from '../components/Icons/Icons'
import Moment from 'moment'
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import api from '../apis/Api';
// @ts-ignore
import Spinner from "react-native-modal-spinner";

export default function DelegateScreen({ route, navigation }: any) {
    const lang = useContext(LanguageContext);
    const { bill } = route.params;


    let [search, updateSearch] = useState("");
    let [employees, setEmployees] = useState([]);
    let [refreshing, setRefreshing] = useState(true);
    const [loading, setLoading] = useState(false);

    let load = () => api.getEmployees()
        .then((result: any) => {
            employees = result;
            setEmployees(result);
            //console.debug(employees);
        })
        .catch((error: any) => {
            console.error(error)
        })
        .finally(() =>
            setRefreshing(false)
        );

    useEffect(() => {
        load();
    }, []);

    let delegate = (item: any) => {
        setLoading(true);

        console.log(item);


        new Promise((resolve, reject) => {
            console.debug(bill.msgId, bill.billID, item.id, bill.comment);
            api.delegate(bill.msgId, bill.billID, bill.supplierID, bill.billDate, item.id, bill.comment)
                .then((result) => {
                    console.debug(result);
                    resolve(result);
                    navigation.replace('Root');
                })
                .catch((error: any) => {
                    console.error(error)
                    reject(error);
                })
        }).finally(() => setLoading(false))
    }

    const renderItem = ({ item }: any) => {
        return (
            <TouchableHighlight
                onPress={() => delegate(item)}
                style={{ margin: 1 }}
                lightColor={Colors.light.listItemBackground}
                darkColor={Colors.dark.listItemBackground}>
                <View
                    style={styles.item}
                    lightColor={Colors.light.listItemBackground}
                    darkColor={Colors.dark.listItemBackground}>
                    <Text
                        lightColor={Colors.light.text}
                        darkColor={Colors.dark.text}>
                        {item.firstName} {item.lastName}
                    </Text>
                </View >
            </TouchableHighlight>
        );
    };

    return (
        <View style={styles.root}>
            { Platform.OS !== 'web' ?
                <Spinner
                    color={Colors.tint}
                    size="large"
                    backgroundColor="transparent"
                    visible={loading}
                /> : <></>}

            <View style={styles.container} >
                <View style={styles.left}>
                    <Text style={styles.header}>{bill.supplierName}</Text>
                    <View style={styles.detail}>
                        <Text>{lang.ID_BILLNR} {bill.billID} {lang.ID_FROM} {Moment(bill.billDate).format('DD/MM/yyyy')}</Text>
                        <Text>{bill.billAmount} {bill.currency}</Text>
                    </View>
                </View>
                <View style={styles.right}>
                    <TouchableOpacity
                        onPress={() => navigation.navigate('PreviewScreen', { bill: bill })}>
                        <Icon size={28} name="file" color={(useColorScheme() === 'light') ? "#000000" : "#ffffff"} />
                    </TouchableOpacity>
                </View>

                <TextInput
                    lightColor={Colors.light.listItemBackground}
                    darkColor={Colors.dark.listItemBackground}
                    placeholder="Search..."
                    style={[styles.input, { color: (useColorScheme() === 'light') ? "#000000" : "#ffffff" }]}
                    onChangeText={updateSearch}
                    value={search}></TextInput>

                <FlatList
                    data={employees.filter((item: any) => item.firstName.toLowerCase().includes(search.toLowerCase()))}
                    renderItem={renderItem}
                    onRefresh={() => load()}
                    refreshing={refreshing}
                />
            </View>
        </View >
    );
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
    },
    container: {

        padding: 20,
    },
    left: {
        width: '70%'
    },
    right: {
        alignSelf: "flex-end",
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10
    },
    detail: {
        marginBottom: 20
    },
    item: {
        margin: 2,
        padding: 10,
        fontSize: 18,
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    itemTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemLeft: {
        width: '70%'
    },
    itemRight: {
        padding: 5,
        alignSelf: 'flex-end',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        margin: 2,
        marginBottom: 10,
        marginTop: 10,
        paddingLeft: 10,
        padding: 12,
        borderWidth: 0,
        borderRadius: 5

    },
});
