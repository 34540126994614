import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Alert, DevSettings } from 'react-native';
import api from '../apis/Api';
import BillListView from '../components/BillListView';
import RNExitApp from 'react-native-exit-app';


export default function TabHomeScreen(props: any) {

  let navigation = useNavigation();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        e.preventDefault();
        RNExitApp.exitApp();
      }),
    [navigation]
  );

  return (
    <BillListView
      load={() => api.getBills()}
      isArchive={false}>
    </BillListView>
  )
} 