import Icon from '../components/Icons/Icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import TabHomeScreen from '../screens/TabHomeScreen';
import TabArchiveScreen from '../screens/TabArchiveScreen';
import TabSettingsScreen from '../screens/TabSettingsScreen';
import { BottomTabParamList, TabHomeParamList, TabArchiveParamList, TabSettingsParamList } from '../types';
import PreviewScreen from '../screens/PreviewScreen';
import HistoryScreen from '../screens/HistoryScreen';
import DetailScreen from '../screens/DetailScreen';
import LanguageContext from '../contexts/LanguageContext'
import DelegateScreen from '../screens/DelegateScreen';
import useBackgroundColor from '../hooks/useBackgroundColor';
import { Alert, Button } from 'react-native';
import { Header } from 'react-native/Libraries/NewAppScreen';
import { TouchableOpacity } from '../components/Themed';
import Layout from '../constants/Layout';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  let lang = React.useContext(LanguageContext);

  return (
    <BottomTab.Navigator
      initialRouteName={"Home"}
      tabBarOptions={{
        activeTintColor: Colors[colorScheme].tint,
        showLabel: true,
        style: {
          // borderTopColor: Colors.tint,
        }
      }
      } >
      <BottomTab.Screen
        name="Home"
        component={TabHomeNavigator}
        options={{
          title: lang.ID_HOME,
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Archive"
        component={TabTwoNavigator}
        options={{
          title: lang.ID_ARCHIVE,
          tabBarIcon: ({ color }) => <TabBarIcon name="archive" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={TabSettingsNavigator}
        options={{
          title: lang.ID_EINSTELLUNGEN,
          tabBarIcon: ({ color }) => <TabBarIcon name="settings" color={color} />,
        }}
      />
    </ BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  if (Layout.isSmallDevice)
    return <Icon size={28} style={{ marginBottom: -3 }} {...props} />;
  else
    return <Icon size={28} style={{ marginBottom: -3, marginRight: -5 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabHomeStack = createStackNavigator<TabHomeParamList>();

function TabHomeNavigator() {
  const lang = React.useContext(LanguageContext);
  const colorScheme = useColorScheme();

  return (
    // <Root>
    <TabHomeStack.Navigator initialRouteName="TabHomeScreen"
    >
      <TabHomeStack.Screen
        name="TabHomeScreen"
        component={TabHomeScreen}

        options={{
          headerTitle: lang.ID_BILLS,
          headerTintColor: Colors.tint,
          //@ts-ignore
          headerLeft: null,
          headerRight: () => (
            <TouchableOpacity style={{
              backgroundColor: 'rgba(52, 52, 52, 0)'
            }} onPress={() => Alert.alert('Info', lang.ID_INFO_HOME)}>
              <Icon size={28} style={{ color: Colors.tintIconDisabled, marginRight: 10 }} name="info" />
            </TouchableOpacity>
          )
        }}
      />
      < TabHomeStack.Screen
        name="PreviewScreen"
        component={PreviewScreen}
        options={{ headerTitle: lang.ID_VORSCHAU, headerTintColor: Colors.tint }}
      />
      < TabHomeStack.Screen
        name="DetailScreen"
        component={DetailScreen}
        options={{ headerTitle: lang.ID_DETAIL, headerTintColor: Colors.tint }}
      />
      < TabHomeStack.Screen
        name="HistoryScreen"
        component={HistoryScreen}
        options={{ headerTitle: lang.ID_VERLAUF, headerTintColor: Colors.tint }}
      />
      < TabHomeStack.Screen
        name="DelegateScreen"
        component={DelegateScreen}
        options={{ headerTitle: lang.ID_DELEGATE, headerTintColor: Colors.tint }}
      />
    </TabHomeStack.Navigator >
  );
}

const TabArchiveStack = createStackNavigator<TabArchiveParamList>();

function TabTwoNavigator() {
  const lang = React.useContext(LanguageContext);
  const colorScheme = useColorScheme();
  return (
    <TabArchiveStack.Navigator >
      <TabArchiveStack.Screen
        name="TabArchiveScreen"
        component={TabArchiveScreen}
        options={{
          headerTitle: lang.ID_ARCHIVE,
          headerTintColor: Colors.tint,
          //@ts-ignore
          headerLeft: null,
          headerRight: () => (
            <TouchableOpacity style={{
              backgroundColor: 'rgba(52, 52, 52, 0)'
            }} onPress={() => Alert.alert('Info', lang.ID_INFO_ARCHIVE)}>
              <Icon size={28} style={{ color: Colors.tintIconDisabled, marginRight: 10 }} name="info" />
            </TouchableOpacity>
          )
        }}
      />
      <TabArchiveStack.Screen
        name="PreviewScreen"
        component={PreviewScreen}
        options={{ headerTitle: lang.ID_VORSCHAU, headerTintColor: Colors.tint }}
      />
      < TabArchiveStack.Screen
        name="HistoryScreen"
        component={HistoryScreen}
        options={{ headerTitle: lang.ID_VERLAUF, headerTintColor: Colors.tint }}
      />
    </TabArchiveStack.Navigator>
  );
}

const TabSettingsStack = createStackNavigator<TabSettingsParamList>();

function TabSettingsNavigator() {
  const lang = React.useContext(LanguageContext);
  const colorScheme = useColorScheme();

  return (
    <TabSettingsStack.Navigator>
      <TabSettingsStack.Screen
        name="TabSettingsScreen"
        component={TabSettingsScreen}
        //@ts-ignore
        options={{ headerTitle: lang.ID_EINSTELLUNGEN, headerTintColor: Colors.tint, headerLeft: null }}
      />
    </TabSettingsStack.Navigator>
  );
}