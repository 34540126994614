import * as React from 'react';

import BillListView from '../components/BillListView';
import api from '../apis/Api';
import global from "../global"

export default function TabArchiveScreen() {

  return (
    <BillListView
      load={() => api.getArchive(global.historyDays)}
      isArchive={true}>
    </BillListView>
  )
}
