import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { View, Text, TouchableOpacity } from '../components/Themed';
import LanguageContext from '../contexts/LanguageContext';
import Icon from '../components/Icons/Icons'
import Moment from 'moment'
import Colors from '../constants/Colors';
import StateBadge from '../components/StateBadge';
import useNoBottomNavigation from '../hooks/useNoBottomNavigation';
import useColorScheme from '../hooks/useColorScheme';
import api from '../apis/Api';
import { useNavigation } from '@react-navigation/native';

function CommentView(props: any) {
    const lang = useContext(LanguageContext);
    const bill = props.bill;
    const navigation = useNavigation();
    useNoBottomNavigation(navigation);

    let [history, setHistory] = useState([]);
    let [refreshing, setRefreshing] = useState(true);

    const [detail, setDetail] = useState<any>();

    useEffect(() => {
        load();

        api.getBillDetail(bill.billID, bill.supplierID, bill.billDate).then((res: any) => {
            console.log(res);
            setDetail(res);
        }).catch(error => console.log(error));
    }, []);

    let load = () => api.getHistory(bill.billID, bill.supplierID, bill.billDate)
        .then((result: any) => {
            history = result;
            setHistory(result);
        })
        .catch((error: any) => {
            console.error(error)
        })
        .finally(() =>
            setRefreshing(false)
        );


    const renderItem = ({ item, index }: any) => {
        return (
            <View>

                <View
                    style={styles.item}
                    lightColor={Colors.light.listItemBackground}
                    darkColor={Colors.dark.listItemBackground}>
                    <View style={styles.itemLeft}
                        lightColor={Colors.light.listItemBackground}
                        darkColor={Colors.dark.listItemBackground}>
                        <Text style={styles.itemTitle}>{item.firstName} {item.lastName}</Text>

                        {item.updated === '0001-01-01T00:00:00' ?
                            <Text
                                lightColor={Colors.light.listItemDetail}
                                darkColor={Colors.dark.listItemDetail}>
                                {Moment().format('DD/MM/yyyy')}</Text>
                            :
                            <Text
                                lightColor={Colors.light.listItemDetail}
                                darkColor={Colors.dark.listItemDetail}>
                                {Moment(item.updated).format('DD/MM/yyyy')}</Text>
                        }

                        <Text
                            lightColor={Colors.light.listItemDetail}
                            darkColor={Colors.dark.listItemDetail}>
                            {item.note}</Text>
                    </View >
                    <View style={styles.itemRight}
                        lightColor={Colors.light.listItemBackground}
                        darkColor={Colors.dark.listItemBackground}>
                        <StateBadge
                            state={item.billState}
                            isOthers={false}
                            hasComment={item.msgCount > 0} />
                    </View>
                </View >

            </View>
        );
    };

    return (
        <View style={{ height: '100%' }}>
            <View style={styles.container} >
                <View style={styles.left}>
                    <Text style={styles.header}>{bill.supplierName}</Text>
                    <View style={styles.detail}>
                        <Text>{lang.ID_BILLNR} {bill.billID} {lang.ID_FROM} {Moment(bill.billDate).format('DD/MM/yyyy')}</Text>
                        <Text>{bill.vatBasisAmount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency}</Text>
                        <Text>IVA: {bill.vatAmount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency}</Text>
                        <Text>Total: {bill.billAmount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency}</Text>

                        <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.DEADLINE}:</Text>
                        {bill.dueDate.map((dd: any) => {
                            return <Text>{dd.amount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency} - {Moment(dd.date).format('DD/MM/yyyy')}</Text>
                        })}

                        {detail ?
                            <View>
                                {detail.accounting.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.ACCOUNT}:</Text>}
                                {detail.accounting.map((a: any) => {
                                    return <Text>{a.amount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency} - {a.id} - {a.description}</Text>
                                })}

                                {detail.cdC.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.CDC}:</Text>}
                                {detail.cdC.map((c: any) => {
                                    return <Text>{c.amount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency} - {c.id} - {c.description}</Text>
                                })}

                                {detail.objects.length > 0 && <Text style={{ fontWeight: "bold" }}>{'\n'}{lang.OBJECT}:</Text>}
                                {detail.objects.map((o: any) => {
                                    return <Text>{o.amount.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} {bill.currency} - {o.id} - {o.description}</Text>
                                })}
                            </View>
                            :
                            <></>
                        }
                    </View>
                </View>
                {/* <View style={styles.right}>
                    <TouchableOpacity
                        onPress={() => navigation.navigate('PreviewScreen', { bill: bill })}>
                        <Icon size={28} name="file" color={(useColorScheme() === 'light') ? "#000000" : "#ffffff"} />
                    </TouchableOpacity>
                </View> */}
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                        lightColor={Colors.light.listItemBackground}
                        darkColor={Colors.dark.listItemBackground}
                        style={[styles.button, styles.textButton, { width: '50%' }]}
                        onPress={() => navigation.navigate('PreviewScreen', { bill: bill })}>
                        <Text>{lang.ID_VORSCHAU}</Text>
                    </TouchableOpacity>
                </View>
            </View>

            <FlatList
                data={history}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                onRefresh={() => load()}
                refreshing={refreshing}
            />
        </View >
    );
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    container: {

        padding: 20,
    },
    left: {
        width: '70%'
    },
    right: {
        alignSelf: "flex-end",
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10
    },
    detail: {
        marginBottom: 20
    },
    item: {
        margin: 2,
        padding: 10,
        fontSize: 18,
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    itemTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemLeft: {
        width: '70%'
    },
    itemRight: {
        padding: 5,
        alignSelf: 'flex-end',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        marginBottom: 10,
        alignItems: "center",
        borderRadius: 5,
        padding: 5
    },
    textButton: {
        padding: 10
    },
});

export default CommentView;
