import React, { useEffect, useState, } from 'react';
import { StyleSheet, Dimensions, Image } from 'react-native';

import { View, Text } from '@infominds/react-native-components';
import ImageZoom from 'react-native-image-pan-zoom';
import Layout from '../../constants/Layout';
export function PdfViewer(props: any) {
    let [source, setSource] = useState('');
    let [type, setType] = useState('');

    const [visible, setIsVisible] = useState(false);

    useEffect(() => {
        props.load().then((res: any) => {
            console.log(res.docType);
            res.docType = res.docType.toLowerCase();

            setType(res.docType)
            setSource(('data:application/' + res.docType + ';base64,') + res.stream);
        })
    }, [])

    if (!source) {
        return <View></View>
    }

    if (type === 'pdf') {
        return (
            <View style={styles.containerTest}>
                <object style={{ height: '100%' }} data={source} type={("application/" + type)}>
                    <iframe src="https://docs.google.com/viewer?&embedded=true"></iframe>
                </object>
            </View>
        );
    } else {
        return (
            <View style={styles.containerTest}>
                <ImageZoom
                    cropWidth={Dimensions.get('window').width}
                    cropHeight={Dimensions.get('window').height}
                    imageWidth={Dimensions.get('window').width}
                    imageHeight={Dimensions.get('window').height}>
                    <View style={styles.container}>
                        <img draggable={false} style={{ objectFit: 'scale-down' }} src={source}></img>
                    </View>
                </ImageZoom>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        // userSelect: 'none'
    },
    containerTest: {
        flex: 1,
        height: '100%',
        width: '100%'
    },
    pdf: {
        flex: 1,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }
});
