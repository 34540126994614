import * as React from 'react';
import { StyleSheet, Switch, DevSettings, Platform, ScrollView, Alert } from 'react-native';

import { Text, TextInput, View } from '../components/Themed';
import ThemeContext from '../contexts/ThemeContext';

import AsyncStorage from "@react-native-community/async-storage"
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import api from '../apis/Api';
import global from "../global"
import ChangeLanguageContext from '../contexts/ChangeLanguageContext';
import LanguageContext from '../contexts/LanguageContext';
import RNPickerSelect from 'react-native-picker-select';
import DeviceInfo from 'react-native-device-info';
import createPersistedState from 'use-persisted-state';

import { LogoutButton } from '@infominds/react-native-license'

import { LicenseGlobals } from '@infominds/react-native-license';
import { useFocusEffect } from '@react-navigation/core';

const useMandantState = createPersistedState('mandantID');

export default function TabSettingsScreen({ navigation }: any) {

  let lang = React.useContext(LanguageContext);
  let setTheme = React.useContext(ThemeContext);
  let setLanguage = React.useContext(ChangeLanguageContext);

  const colorScheme = useColorScheme();

  const [wait, setWait] = React.useState(false);
  const waitSwitch = () => setWait((previousState: boolean) => {
    AsyncStorage.setItem('wait', String(!previousState));

    return !previousState
  });

  const [isEnabled, setIsEnabled] = React.useState(global.theme === 'dark');
  const toggleSwitch = () => setIsEnabled((previousState: boolean) => {
    if (previousState == true) {
      setTheme('light');
      AsyncStorage.setItem('theme', 'light')
        .then(() => {
          if (Platform.OS === 'ios' || Platform.OS === 'android')
            DevSettings.reload()
        });

    } else {
      setTheme('dark');
      AsyncStorage.setItem('theme', 'dark')
        .then(() => {
          if (Platform.OS === 'ios' || Platform.OS === 'android')
            DevSettings.reload()
        });

    }
    return !previousState
  });

  const [selectedMandant, setSelectedMandant] = useMandantState<string>("-1");
  const [selectedLanguage, setSelectedLanguage] = React.useState(global.language);
  const [mandants, setMandants] = React.useState([]);
  const [historyDays, setHistoryDays] = React.useState(global.historyDays);
  const [username, setUsername] = React.useState('');

  // console.log(LicenseGlobals.mandantId)
  console.log(selectedMandant)

  useFocusEffect(React.useCallback(() => {
    api.getMandants().then((resultMandant: any) => {
      setMandants(resultMandant);
      console.log('mandants', resultMandant)
      // if (LicenseGlobals.mandantId != -1)
      //   setSelectedMandant(Number(LicenseGlobals.mandantId));

      console.log('LicenseGlobals.mandantId', LicenseGlobals.mandantId)
      console.log('LicenseGlobals.erp', LicenseGlobals.erp)

      if (LicenseGlobals.mandantId)
        setSelectedMandant(LicenseGlobals.mandantId.toString())
      if (LicenseGlobals.mandantId === "NaN") {
        api.getUserInfo()
          .then((result: any) => {
            //  AsyncStorage.getItem('mandantId').then(res => { if (res) setSelectedMandant(Number(res)) });
            console.log('userInfo', result)

            AsyncStorage.getItem('mandantId')
              .then(man => {
                console.log('esult.code', result.code)
                if (man !== null) {
                  console.log('man', man)
                  if (man !== '' && man !== 'NaN' && Number(man) !== -1) {
                    console.log('manman', man)
                    setSelectedMandant(man)
                  } else {
                    console.log('result.code', result.code)
                    if (result.code !== '')
                      setSelectedMandant(result.code);
                  }
                }
                // LicenseGlobals.mandantId = selectedMandant;
              })

          })
          .catch((error) => console.error(error))
      }

    })
  }, []))

  React.useEffect(() => {
    AsyncStorage.getItem('wait').then(res => {
      if (res) setWait(JSON.parse(res));
    });

    AsyncStorage.getItem('username').then(un => {
      if (un) setUsername(un);
    })
  }, []);

  const pickerLanguage = (
    <RNPickerSelect
      onValueChange={(value: string) => {
        if (value) {
          setSelectedLanguage(value);
          AsyncStorage.setItem('lang', value);
          global.language = value;
          setLanguage(value);
        }
      }}
      value={selectedLanguage}
      style={{
        inputIOS: { color: Colors[colorScheme].text },
        inputAndroid: { color: Colors[colorScheme].text, height: 40 },
        inputAndroidContainer: { alignSelf: 'flex-end' },
        inputIOSContainer: { alignSelf: 'flex-end', padding: 10, height: 40 }
      }}
      items={[
        { label: 'Deutsch', value: 'de' },
        { label: 'Italiano', value: 'it' },
        { label: 'English', value: 'en' },
      ]}
    />
  );

  const PickerMandats = () => (
    <RNPickerSelect
      onValueChange={(value) => {
        if (value != -1 && value != null && value != selectedMandant) {
          setSelectedMandant(value.toString());
          AsyncStorage.setItem('changedMandant', 'true');
          LicenseGlobals.mandantId = value;
          AsyncStorage.setItem('mandantId', value.toString());
        }
      }}
      value={selectedMandant}
      style={{
        inputIOS: { color: Colors[colorScheme].text },
        inputAndroid: { color: Colors[colorScheme].text, height: 40 },
        inputAndroidContainer: { alignSelf: 'flex-end' },
        inputIOSContainer: { alignSelf: 'flex-end', padding: 10, height: 40 }
      }}
      items={
        mandants.map((item: any) => {
          return { label: item.description, value: item.id }
        })
      }
    />
  );


  const Settings = ({ title, content }: any) => (
    <View style={[styles.settingsItem, { borderTopColor: Colors[colorScheme].listItemBackground, }]}
      lightColor={Colors.light.background}
      darkColor={Colors.dark.background}>
      <Text
        style={{ color: Colors[colorScheme].text, textAlignVertical: 'center', width: '50%' }}
        lightColor={Colors.light.text}
        darkColor={Colors.dark.text}>
        {title}</Text>
      <View>
        {content}
      </View>

    </View>
  )

  return (
    <View style={styles.container}
      lightColor={Colors.light.background}
      darkColor={Colors.dark.background}>
      <ScrollView style={{ flex: 1 }}>
        {/* <ScrollView > */}
        <View style={styles.settings}
          lightColor={Colors.light.background}
          darkColor={Colors.dark.background}>

          <View style={[styles.settingsItem, { borderTopWidth: 0, marginTop: 0 }]}
            lightColor={Colors.light.background}
            darkColor={Colors.dark.background}>
            <Text
              style={{ color: Colors[colorScheme].text, textAlignVertical: 'center', width: '50%' }}
              lightColor={Colors.light.text}
              darkColor={Colors.dark.text}>
              {lang.ID_DARK_MODE}</Text>
            <View>
              <Switch
                trackColor={{ false: "#767577", true: "#767577" }}
                thumbColor={isEnabled ? Colors.tint : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={toggleSwitch}
                value={isEnabled}
                style={{ alignSelf: 'flex-end' }}
              />
            </View>

          </View>

          {Platform.OS !== 'android' ?
            <Settings
              title={lang.ID_SPRACHE}
              content={
                <>
                  {pickerLanguage}
                </>
              }>
            </Settings>
            :
            <View style={{ borderTopWidth: 1, borderTopColor: Colors[colorScheme].listItemBackground, marginTop: 10 }}>
              <Text style={{ alignSelf: 'flex-start' }}>{lang.ID_SPRACHE}</Text>
              {pickerLanguage}
              <View style={styles.separator}></View>
            </View>
          }

          {Platform.OS !== 'android' ?
            <Settings
              title={lang.ID_MANDANTS}
              content={
                <PickerMandats />
              }>
            </Settings>
            :
            <View style={{ borderTopWidth: 1, borderTopColor: Colors[colorScheme].listItemBackground, marginTop: 10 }}>
              <Text style={{ alignSelf: 'flex-start' }}>{lang.ID_MANDANTS}</Text>
              <PickerMandats />
              <View style={styles.separator}></View>
            </View>
          }

          <View style={[styles.settingsItem, { borderTopColor: Colors[colorScheme].listItemBackground, }]}
            lightColor={Colors.light.background}
            darkColor={Colors.dark.background}>
            <Text
              style={{ color: Colors[colorScheme].text, textAlignVertical: 'center', width: '50%' }}
              lightColor={Colors.light.text}
              darkColor={Colors.dark.text}>
              {lang.ID_HISTORYDAYS}</Text>
            <TextInput
              lightColor={Colors.light.listItemBackground}
              darkColor={Colors.dark.listItemBackground}
              style={[styles.input, { color: Colors[colorScheme].text }]}

              onChangeText={text => {
                setHistoryDays(Number(text));
                global.historyDays = Number(text);
                AsyncStorage.setItem('historyDays', text);
              }}
              keyboardType='number-pad'
              value={historyDays.toString()}></TextInput>
          </View>

          <Settings
            title={'Always wait for result (~30s)'}
            content={
              <Switch
                trackColor={{ false: "#767577", true: "#767577" }}
                thumbColor={wait ? Colors.tint : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={waitSwitch}
                value={wait}
                style={{ alignSelf: 'flex-end' }}
              />
            }>
          </Settings>

          <Settings
            title={lang.ID_USERNAME}
            content={
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  alignSelf: 'flex-end'
                }}
                lightColor={Colors.light.text}
                darkColor={Colors.dark.text}>
                {username}</Text>
            }>
          </Settings>

          <Settings
            title={lang.SETTINGS_LICENSE}
            content={
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  alignSelf: 'flex-end'
                }}
                lightColor={Colors.light.text}
                darkColor={Colors.dark.text}>
                {LicenseGlobals.license}</Text>
            }>
          </Settings>

          <Settings
            title='Server'
            content={
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  alignSelf: 'flex-end'
                }}
                lightColor={Colors.light.text}
                darkColor={Colors.dark.text}>
                {LicenseGlobals.baseUrl}</Text>
            }>
          </Settings>

          <Settings
            title={lang.ID_APP_VERSION}
            content={
              <Text
                style={{
                  color: Colors[colorScheme].text,
                  alignSelf: 'flex-end'
                }}
                lightColor={Colors.light.text}
                darkColor={Colors.dark.text}>
                {DeviceInfo.getVersion()}</Text>
            }>
          </Settings>

          <LogoutButton logoutNavigation={() => {



            Alert.alert(
              "Logout",
              lang.LOGOUT_MESSAGE,
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                {
                  text: "OK", onPress: () => {
                    AsyncStorage.removeItem('selectedCompany');
                    AsyncStorage.removeItem('mandantId');
                    AsyncStorage.removeItem('mandantID');
                    AsyncStorage.removeItem('username');
                    AsyncStorage.removeItem('password');
                    navigation.navigate('Login')
                  }
                }
              ]
            );

          }}></LogoutButton>
        </View>
      </ScrollView>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  settingsItem: {
    marginTop: 15,
    paddingTop: 5,
    borderTopWidth: 1
  },
  settings: {
    padding: 10,
  },
  pdfreader: {
    backgroundColor: '#123456'
  },
  gridMargin: {
    marginTop: 40,
    marginBottom: 20
  },
  input: {
    marginBottom: 10,
    padding: 5,
    width: '20%',
    borderWidth: 0,
    borderRadius: 5,
    color: '#ffffff',
    alignSelf: 'flex-end'

  },
  separator: {
    borderBottomWidth: 1,
    // borderBottomColor: Colors.,
  },
});
