import './navigation/GestureHandler';

import React, { SetStateAction, useEffect } from 'react';
import {
  ColorSchemeName,
  KeyboardAvoidingView,
  LogBox,
  Platform,
  StatusBar,
  StyleSheet,
} from 'react-native';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import AsyncStorage from "@react-native-community/async-storage"

import LanguageContext from './contexts/LanguageContext';
import ThemeContext from './contexts/ThemeContext';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import useLanguage from './hooks/useLanguage';
import Navigation from './navigation';
import * as g from './global'
import ChangeLanguageContext from './contexts/ChangeLanguageContext';
import Colors from './constants/Colors';
import SplashScreen from 'react-native-splash-screen';
import ColorSchemeContext from './contexts/ColorSchemeContext';
import log, { setupExceptionHandler } from './libs/logging';

import Analytics from 'appcenter-analytics';
import LicenseGlobals from '@infominds/react-native-license';

export declare const global: { HermesInternal: null | {} };

export default function App() {
  const isLoadingComplete = useCachedResources();
  const initColorScheme = useColorScheme();
  const [colorScheme, setTheme] = React.useState(initColorScheme);
  const [lang, setLanguage] = React.useState(useLanguage());

  AsyncStorage.getItem('lang').then(lang => {
    if (lang) {
      setLanguage(useLanguage(lang));
      g.default.language = lang;
    }
  });

  AsyncStorage.getItem('theme')
    .then((x) => {
      if (x != null) {
        g.default.theme = x;
        setTheme(x as NonNullable<ColorSchemeName>);
      }
    });

  AsyncStorage.getItem('historyDays')
    .then((x) => {
      if (x != null) {
        g.default.historyDays = Number(x);
      }
    })

  AsyncStorage.getItem('mandantId')
    .then(mandantId => {
      console.debug("mandant" + mandantId);
      if (mandantId != null) {
        g.default.mandantId = mandantId;
      }
    });



  if (Platform.OS !== 'web') {
    setupExceptionHandler();
    //log.info('Hello {Name}!', 'Alex');

    // ! remove the waning messages
    LogBox.ignoreAllLogs(true);
  }

  useEffect(() => {
    if (Platform.OS !== 'web') {
      SplashScreen.hide();
      Analytics.setEnabled(true)
    }
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <LanguageContext.Provider value={lang}>
          <ChangeLanguageContext.Provider value={setLanguage}>
            <ColorSchemeContext.Provider value={colorScheme}>
              <ThemeContext.Provider value={setTheme}>
                <KeyboardAvoidingView
                  behavior={Platform.OS == "ios" ? "padding" : "height"}
                  style={styles.container} >
                  <Navigation
                    colorScheme={colorScheme} />
                  <StatusBar
                    backgroundColor={Colors[colorScheme].background}
                    barStyle={colorScheme === "dark" ? "light-content" : "dark-content"} />
                </KeyboardAvoidingView>
              </ThemeContext.Provider>
            </ColorSchemeContext.Provider>
          </ChangeLanguageContext.Provider>
        </LanguageContext.Provider>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});