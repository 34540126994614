import React from 'react';
import { StyleSheet } from 'react-native';
import { TextInput, useThemeColor } from './Themed';

export function Comment(props: any) {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');
    return (
        <TextInput
            style={[styles.comment, { backgroundColor }, style
            ]}{...otherProps}
        />
    )
}

const styles = StyleSheet.create({
    comment: {
        minHeight: 80,
        padding: 10,
        width: '100%',
        borderWidth: 0,
        borderRadius: 5,
        color: '#ffffff'
    }
});
