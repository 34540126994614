const tintColor = '#26d07c';
const tintOpposite = '#FF594B';

export default {
  tint: tintColor,
  tintOpposite: tintOpposite,
  tintIconDisabled: '#7C7C7D',
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    listItemBackground: '#E6E6E6',
    listItemDetail: '#2e2e2e',
    placeholderColor: '#6C6C6C'
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    listItemBackground: '#394A55',
    listItemDetail: '#D4FFFFFF',
    placeholderColor: '#A4A4A4'
  },
};
